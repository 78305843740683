// @use '@carbon/react/scss/themes';
// @use '@carbon/react/scss/theme' with ($theme: themes.$g90);

html, body {
    background: rgb(38, 38, 38);
    background-color: rgb(38, 38, 38);
}

.App {
    margin: 0px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

/* On screens that are over 1024px, set the app to a max-width of 500px -> (somewhat) prevents weirdness for tablets (they usually go up to 1024px) */
@media screen and (min-width: 1024px) {
    .App {
        max-width: 500px;
    }
}
  

.cds--btn {
    max-width: unset;
    width: 100%;
}

/* Header */

.smheader {
    min-height: 80px;
    background-color: #161616;
    color: #F4F4F4;
    display: flex;
    flex-direction: column;
}

.smheader .header-empty {
    min-height: 32px;
}

.smheader .header-line {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    text-align: center;
    margin-right: 1px;
}

.grow {
    flex-grow: 10;
}

.margin-top {
    margin-top: 2em;
}

.spacer {
    flex-grow: 2;
}
  
.flex-h {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-c {
    display: flex;
    flex-direction: column;
}
  
.flex-h-only {
    display: flex;
    flex-direction: row;
    position: relative;
}

.sm-row {
    min-height: 48px;
    display: flex;
    flex-direction: row;
    background-color: #393939;
    border-top: 1px solid #525252;
}

.sm-row-maxheight {
    max-height: 48px;
}

.text-content {
    margin-top: 2em;
    margin-left: 4em;
    margin-right: 4em;
    color: #8D8D8D;
}